import CardStockOrder from "./cardStockOrder"
import { OtherOrderAdmin } from "./orderAdmin"
import { Refund } from "./refund"

export enum PaiementType {
  Cheque = "CHEQUE",
  Cash = "CASH",
  Deux = "DEUX",
  CB = "CB",
}

export function getPaiementTypeLabel(type: PaiementType | string) {
  switch (type) {
    case PaiementType.Cheque:
      return "Chèque"
    case PaiementType.Cash:
      return "Espèces"
    case PaiementType.Deux:
      return "Espèces et Chèque"
    case PaiementType.CB:
      return "Carte Bancaire"
    default:
      return type
  }
}

export interface Paiement {
  type: PaiementType
  amount?: number
  chequeNumber?: string
  otherOrders?: OtherOrderAdmin[]
  cardStockOrder?: CardStockOrder
  refunds?: Refund[]
  amountCash?: number
  amountCheque?: number
  amountCB?: number
}
